import logo from "../../assets/MH_logo.png";
export function SplashScreen() {
  return (
    <div
      style={{
        display: "grid",
        placeContent: "center",
        backgroundColor: "#fff",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 1400,
      }}
    >
      <img src={logo} alt="logo" style={{ width: "230px" }} />
    </div>
  );
}
